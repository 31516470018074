import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import PubMap from "../../components/PubMap"
import { buildBlocks } from "../../services/builder"

export const query = graphql`
  query {
    wpPage(uri: { eq: "/guest-feedback/find-a-pub/" }) {
      id
      slug
      title
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
  }
`

const FindAPub = ({ data, location }) => {
  const blocks = buildBlocks(data.wpPage.blocks, {}, {}, data.wpPage.id)
  return (
    <Layout>
      {blocks}
      <PubMap state={location?.state} feedbackStyle={true}  />
    </Layout>
  )
}

export default FindAPub
